import React from "react";
import Image404 from "../assets/img/svg/404 Error Page not Found with people connecting a plug-rafiki.svg"
import Styled from "styled-components";

const Img404 = Styled.img`
    max-height: 100vh ;
`
export const NotFound = () => {
    return (
        <>
            <main className="container my-4 text-center">
                <section className="py-5">
                </section>
            </main>

        </>
    )
}